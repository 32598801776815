<template>
  <div class="box">
    <img
      v-if="iconType == 1"
      class="icon"
      src="../../assets/images/loginIcon1.png"
      alt=""
    />
    <img
      v-if="iconType == 2"
      class="icon"
      src="../../assets/images/pwdicon.png"
      alt=""
    />
    <input
      class="input"
      :type="isPwd ? 'password' : 'text'"
      :value="value"
      :placeholder="placeholder"
      @input="change"
      maxlength="maxlength"
    />
    <img
      v-if="iconType == 2 && !isPwd"
      class="icon"
      src="../../assets/images/pwdHidden.png"
      alt=""
      @click="isPwd = !isPwd"
    />
    <img
      v-if="iconType == 2 && isPwd"
      class="icon"
      src="../../assets/images/hiddenPwd.png"
      alt=""
      @click="isPwd = !isPwd"
    />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    placeholder: { type: String, default: "请输入" },
    maxlength: {
      type: Number,
      default: 20,
    },
    iconType: {
      type: Number,
      default: 0,
    },
    value: { type: String, default: "" },
  },
  data() {
    return {
      isPwd: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    change(e) {
      this.$emit("input", e.target.value);
    },
  },
  created() {},
  mounted() {
    if (this.iconType == 2) {
      this.isPwd = true;
    }
    console.log(this.value)
  },
};
</script>
<style lang="less" scoped>
.box {
  width: 300px;
  height: 52px;
  border-radius: 52px;
  background-color: #eeeeee;
  margin: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 14px;
  .icon {
    width: 24px;
    height: 24px;
  }
  .input {
    height: 24px;
    width: calc(100% - 40px);
    padding: 0 16px;
    color: #222222;
    font-size: 16px;
    font-weight: 500;
    background-color: #eeeeee;
  }
}
</style>
