import axios from "axios";
import Cookies from "js-cookie";
import { Toast } from "vant";
import Store from "@/store/index";

const service = axios.create({
  baseURL: "https://api.novellettes.com",
  // baseURL: "http://127.0.0.1:8080",
  headers: {
    Authorization: `Bearer ${Cookies.get("atoken")}`,
    "Refresh-Token": Cookies.get("rtoken"),
  },
});
const serviceCache = axios.create({
  baseURL: "https://static.novellettes.com/static/img/novel",
  // baseURL: "http://127.0.0.1:8080/static/img/novel",
})
export const request = {};
request.get = (url, params = {}, config = {}) =>
  service.get(url, { ...config, params });
request.post = (url, data, config = {}) => service.post(url, data, config);
request.put = (url, data, config = {}) => service.put(url, data, config);
request.delete = (url, params, config = {}) =>
  service.delete(url, { ...config, params });
request.patch = (url, data, config = {}) => service.patch(url, data, config);

export const updateService = () => {
  service.defaults.headers.Authorization = `Bearer ${Cookies.get("atoken")}`;
  service.defaults.headers["Refresh-Token"] = Cookies.get("rtoken");
};

// 添加一个请求拦截器
service.interceptors.request.use(function (config) {
  return config;
});

// 添加一个响应拦截器
service.interceptors.response.use(
  (res) => {
    const responseData = res.data || {};
    return responseData || {};
  },
  (err) => {
    Toast.clear();
    return Promise.reject(err);
  }
);

const urls = {
  fbLogin: "/api/v1/loginByFaceBook",
  logout: "/api/v1/logout",
  report: "/api/v1/report",
  categoryList: "/api/v1/category/list",
  bookList: "/api/v1/book/list",
  cagegoryBookList: "/api/v1/books/category/",
  bookDetail: "/api/v1/book/",
  chapterList: "/api/v1/chapter/",
  vipChapterList: "/api/v1/vip/chapter/",
  userInfo: "/api/v1/user/",
  bookShelfAct: "/api/v1/bookshelf",
  bookShelfList: "/api/v1/bookshelf/list",
  subscription: "/api/v1/order/subscription/",
  planList: "/api/v1/plan/list",
  orderPatch: "/api/v1/order/patch",

  //海龙测试pay使用
  createPaypalOrder: "/api/v1/create-paypal-order",
  capturePaypalOrder: "/api/v1/capture-paypal-order",

  //登录注册
  registerByName: "/api/v1/registerByName",
  loginByName: "/api/v1/loginByName",

  rechargeList: "/trade/open/recharge_list",
  orderSubmit: "/trade/open/order/submit",
};

export const rechargeListApi = async function (data = {}) {
  try {
    return await request.get(urls.rechargeList, data);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const orderSubmitApi = async function (data = {}) {
  try {
    return await request.post(urls.orderSubmit, data);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const registerByNameApi = async function (data = {}) {
  try {
    return await request.post(urls.registerByName, data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const loginByNameApi = async function (data = {}) {
  try {
    return await request.post(urls.loginByName, data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createPaypalOrderApi = async function (data = {}) {
  try {
    return await request.post(urls.createPaypalOrder, data);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const capturePaypalOrderApi = async function (data = {}) {
  try {
    return await request.post(urls.capturePaypalOrder, data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const orderPatch = async function (data = {}) {
  try {
    return await request.post(urls.orderPatch, data);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const getPlanList = async function (data = {}) {
  try {
    return await request.get(urls.planList, data);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const apiAddBookShelf = async function (data = {}) {
  try {
    data.user_id = +Cookies.get("uid");
    return await request.post(urls.bookShelfAct, data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const apiGetBookShelfList = async function (data = {}) {
  try {
    data.UserID = +Cookies.get("uid");
    return await request.get(urls.bookShelfList, data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const apiFbLogin = async function (data = {}) {
  try {
    return await request.post(urls.fbLogin, data);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const apiLogout = async function (data = {}) {
  try {
    return await request.post(urls.logout, data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const apiLogReport = async function (data = {}) {
  try {
    return await request.post(urls.report, data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const apiCategoryList = async function (data = {}) {
  try {
    return await request.get(urls.categoryList, data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const apiBookList = async function (data = {}) {
  try {
    return await request.get(urls.bookList, data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const apiCagegoryBookList = async function (data = {}) {
  const api = urls.cagegoryBookList + data.id;
  try {
    return await request.get(api, data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const apiGetBookDetail = async function (data = {}) {
  const api = urls.bookDetail + data.id;
  try {
    return await request.get(api, data);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const subscriptionApi = async function (data = {}) {
  const api = urls.subscription + data.id;
  try {
    return await request.post(api);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const apiGetChapterList = async function (data = {}) {
  if (Object.hasOwn(data, 'cache') && Object.hasOwn(data, "source")) {
    if(data.cache) {
      return await aipGetChapterListByCache(data);
    }
  }
  const api = urls.chapterList + data.bookId + "/" + data.chapterId ;
  data.uuid = Cookies.get("abuid");
  try {
    return await request.get(api, data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const aipGetChapterListByCache = async function (data = {}) {
  
  // const api = urls.chapterList + data.bookId + "/" + data.chapterId;
  try {
    return  await serviceCache.get(`/${data.source}/${data.source}${data.chapterId}.txt`, { responseType: 'arraybuffer' }).then(res => {
      if (res.status !== 200){
        return "";
      }
      const decoder = new TextDecoder("utf-8");
      const text = decoder.decode(res.data);
      // console.log(text);
      return text;
    });
    // return await request.get(api, data);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const apiGetVipChapterData = async function (data = {}) {
  data.userID = +Cookies.get("uid");
  const api = urls.vipChapterList + data.bookId + "/" + data.chapterId;
  try {
    return await request.get(api, data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const apiGetUserInfo = async function (data = {}) {
  const api = urls.userInfo + Cookies.get("uid");
  try {
    return await request.get(api, data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const apiGetUserInfoByUUID = async (data={}) => {
  const api = urls.userInfo + Cookies.get("abuid");
  try {
    return await request.get(api, data);
  } catch (e) {
    return Promise.reject(e);
  }
}


/*
  params {
    project:"",// pageName or other
    user:Cookies.get("abuid"),
    cid:pid, // int
    event:"click", // view 
    ts: new Date().getTime(), // int
    app_id:"novellettes",
    lang:window.navigator.language,
  }
*/

export const getParams = function () {
  const _params = {
    project: "",
    user: Cookies.get("abuid"),
    cid: 0, // int
    ts: new Date().getTime(), // int
    app_id: "novellettes",
    lan: window.navigator.language,
    event: "click",
  };
  return _params;
};
export const reportInfo = async (params) => {
  if (typeof params !== "object") {
    return 0;
  }
  const api = urls.report;
  const _params = getParams();
  params = Object.assign(_params, params);
  try {
    return await request.post(api, params);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const reportFBInfo2 = async ()=>{
  const _params = GetReportParam2();
  const api = urls.report;
  // if (!_params.properties.ad_pid) {
  //   return 0;
  // }

  try {
    return await request.post(api, _params);
  } catch (error) {
    return Promise.reject(error);
  }
}


export const reportFBInfo = async () => {
  const _params = GetReportParam();
  const api = urls.report;
  // if (!_params.properties.ad_pid) {
  //   return 0;
  // }

  try {
    return await request.post(api, _params);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const GetReportParam = () => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const pid =searchParams.get("pid") || "130500";
  const params = {
    project: "",
    user: Cookies.get("abuid"),
    cid: pid, //
    ts: new Date().getTime(), // int
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    app_id: "novellettes",
    lan: window.navigator.language,
    event: "track",
  };

  const properties = {
    url: window.location.href || "",
    referrer: document.referrer || "",
    screen_width: window.screen.width || "",
    screen_height: window.screen.height || "",
    screen_color: window.screen.colorDepth || "",
    screen_pixel: window.screen.pixelDepth || "",
    ua: navigator.userAgent,
    browser: navigator.appName,
    browser_version: navigator.appVersion,
    platform: navigator.platform,
    url_search: url.search,
    h5from: searchParams.get("h5from") || "",
    ad_pid: pid, // 广告渠道
    ad_c: searchParams.get("c") || "", // 广告系列名称
    ad_af_c_id: searchParams.get("af_c_id") || "", // 广告系列id
    ad_af_adset: searchParams.get("af_adset") || "", // 广告组名称
    ad_af_adset_id: searchParams.get("af_adset_id") || "", // 广告组id
    ad_af_ad: searchParams.get("af_ad") || "", // 广告名称
    ad_af_ad_id: searchParams.get("af_ad_id") || "", // 广告id
    channel_id: ""+pid , // 渠道id  searchParams.get("channel_id") || "default"
    event_name: "init_app"
  };

  if (Object.hasOwn(Store.state, "userData")) {
    properties.fbid = Store.state.userData.fbid || ""; // facebook id
    properties.uuid = Store.state.userData.uuid || ""; //  uuid
  }

  if (searchParams.get("pid")) {
    params.project = "tf_novallettes";
  }

  params.properties = properties;

  return params;
};



export const GetReportParam2 = () => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const pid =searchParams.get("pid") || "130500";
  const params = {
    project: "",
    user: Cookies.get("abuid"),
    cid: pid, //
    ts: new Date().getTime(), // int
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    app_id: "novellettes",
    lan: window.navigator.language,
    event: "pixel_report",
  };
  const _fbp = Cookies.get("_fbp");
  const _fbc = Cookies.get("_fbc");
  const properties = {
    fb_id:"",
    fbp:_fbp,
    fbc:_fbc,
    client_user_agen:location.href,
    event_source_url:"",
    event_name: "pixel_report"
  }

  if (searchParams.get("pid")) {
    params.project = "tf_novallettes";
  }

  params.properties = properties;

  return params;

}

export const generateSecureCMID = () =>{
  const randomBytes = crypto.lib.WordArray.random(16);
  const cmid = crypto.enc.Hex.stringify(randomBytes)
  return cmid;
 }


 export const checkPayStatus = ()=>{
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  return searchParams.has("is_succ") ? searchParams.get("is_succ"): ""; 
 }

 export const deleteSearch = (key)=>{
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);  
  if(searchParams.has(key)){
    searchParams.delete(key)
  }
  return searchParams;
 }