import dayjs from "dayjs";
export const insertFB = () => {
  // now: 2024-11-12 comment out not to load fb-login sdk
  // const lang = navigator.language;
  // const url = `https://connect.facebook.net/${lang}/sdk.js`;
  // const sdkScript = document.createElement('script');
  // sdkScript.src = url;
  // sdkScript.async = true;
  // document.body.appendChild(sdkScript);
  // sdkScript.addEventListener('load', () => {
  //     window.fbAsyncInit = () => {
  //       window.FB.init({
  //         appId: '190081899933357', // online: 190081899933357 local:506122645508460 
  //         cookie: true,
  //         xfbml: true,
  //         version: 'v20.0'
  //       });
  //       window.FB.AppEvents.logPageView();
  //     };
  // })
}



export const numberFormat = (num) => {
  if(!num) return 0;
  const sm = (num/1000).toFixed(1);
  return `${sm} <small>K</small>`
}

export const dayFormat = (day) => {
  if(!day) return 0;
  return dayjs(day).format('YYYY-MM-DD HH:mm:ss')
}

export const genUUID = ()=>{
  if(typeof crypto !== 'undefined' && crypto.randomUUID){
    return crypto.randomUUID();
  }
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (dt + Math.random()*16)%16 | 0;
    dt = Math.floor(dt/16);
    return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  return uuid;

}

export  const  genRandomString = function(length) {
  length = length || 32;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let result = '';
  const array = new Uint8Array(length);
  crypto.getRandomValues(array);
  for (let i = 0; i < length; i++) {
    result += characters.charAt(array[i] % charactersLength);
  }
  return result;
}


export const insertFncls = function(){
  const sdkScript = document.createElement('script');
  sdkScript.type="application/json";
  sdkScript.id="fncls";
  sdkScript.fncls="fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99"
  sdkScript.setAttribute('fncls', 'fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99');
  const s = "P3PEPDLP5VRHW" // sandbox- P3PEPDLP5VRHW  prod- LS8FHZYB2GQRJ
  sdkScript.textContent = JSON.stringify({
    "f":genRandomString(32),
    "s":`Clockwise_${s}_BA`       // unique ID for each web page
  });
  document.body.appendChild(sdkScript);
  console.log('fncls loaded')
  const sdk = document.createElement('script');
  sdk.src = "https://c.paypal.com/da/r/fb.js";
  sdk.type="text/javascript";
  sdk.async = true;
  document.body.appendChild(sdk);
  sdk.onload=function(){
    console.log("sdk load");
  }
  console.log('fncls loaded')
} 