<template>
  <div class="login-popup">
    <div class="btn-back" @click="cancelLogin"></div>
    <div class="login-con">
      <h2 class="fx-vc">
        <img loading="lazy" src="@/assets/images/logo-icon.png" alt="" />
        DailyRead
      </h2>

      <div class="login-form">
        <div class="the-title">
          {{ isLoginType ? "Login" : "Register" }}
        </div>
        <inputBox
          v-model="userName"
          placeholder="UserName"
          :iconType="1"
        ></inputBox>
        <inputBox
          v-model="password"
          placeholder="PassWord"
          :iconType="2"
        ></inputBox>
        <div
          class="btn-login fx-vc"
          :class="{ disabled: !userName || !password }"
          @click="toLogin"
        >
          {{ isLoginType ? "Login" : "OK" }}
        </div>
        <div class="fx-vb bot-tip">
          <div class="tips">
            {{
              isLoginType
                ? "If you don't have an account"
                : "If you have an account"
            }}
          </div>
          <div class="link" @click="changeLoginType">
            {{ isLoginType ? "Register" : "Login" }}
          </div>
        </div>
      </div>

      <!-- <div class="login-btn fx-vc" @click="loginAct">
        <div class="the-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            fill="none"
            version="1.1"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <g>
              <path
                d="M10.6833,20C10.6833,20,1.10333,20,1.10333,20C0.810634,19.9998,0.529999,19.8834,0.323108,19.6763C0.116217,19.4693,-8.33577e-8,19.1885,4.48418e-14,18.8958C4.48418e-14,18.8958,4.47378e-14,1.10417,4.47378e-14,1.10417C4.47378e-14,0.494167,0.494167,0,1.10417,0C1.10417,0,18.8958,0,18.8958,0C19.5058,0,20,0.494167,20,1.10417C20,1.10417,20,18.8958,20,18.8958C20,19.5058,19.5058,20,18.8958,20C18.8958,20,13.8,20,13.8,20C13.8,20,13.8,12.255,13.8,12.255C13.8,12.255,16.4,12.255,16.4,12.255C16.4,12.255,16.7883,9.23667,16.7883,9.23667C16.7883,9.23667,13.8,9.23667,13.8,9.23667C13.8,9.23667,13.8,7.30833,13.8,7.30833C13.8,6.435,14.0417,5.83917,15.2958,5.83917C15.2958,5.83917,16.8942,5.83917,16.8942,5.83917C16.8942,5.83917,16.8942,3.13917,16.8942,3.13917C16.6175,3.10167,15.6692,3.02,14.565,3.02C12.26,3.02,10.6825,4.42667,10.6825,7.01C10.6825,7.01,10.6825,9.235,10.6825,9.235C10.6825,9.235,8.07667,9.235,8.07667,9.235C8.07667,9.235,8.07667,12.2542,8.07667,12.2542C8.07667,12.2542,10.6825,12.2542,10.6825,12.2542C10.6825,12.2542,10.6825,20,10.6825,20C10.6825,20,10.6833,20,10.6833,20C10.6833,20,10.6833,20,10.6833,20Z"
                fill="#3875EA"
                fill-opacity="1"
              />
            </g>
          </svg>
        </div>
        <div class="the-txt">Continue with Facebook</div>
      </div> -->
      <div class="txt">
        By continuing, you agree with the
        <a href="/privacy.html">Privacy.Policy.</a>and
        <a href="/service.html">Terms of Service</a>
      </div>
      <!-- <payCom></payCom> -->
    </div>
  </div>
</template>

<script>
import {
  apiFbLogin,
  updateService,
  registerByNameApi,
  loginByNameApi,
} from "@/api";
import Cookies from "js-cookie";
import inputBox from "../inputBox/inputBox.vue";
// import Toast from "@vant/weapp/toast/toast";

export default {
  name: "popLogin",
  data() {
    return {
      userName: "",
      password: "",
      isLoginType: true,
    };
  },
  components: { inputBox },
  computed: {},
  methods: {
    cancelLogin() {
      this.$store.commit("setShowLogin", false);
    },
    loginAct() {
      window.FB.login(
        (response) => {
          console.log(response, "facebook---");
          if (response.status === "connected") {
            this.fbLogin(response);
          }
        },
        { scope: "public_profile" }
      );
    },
    async fbLogin(fbData) {
      try {
        this.$toast.loading({
          duration: 0,
        });
        fbData.uuid = this.$store.state.abuid;
        const { code, data } = await apiFbLogin(fbData);
        if (code) return;
        this.$store.commit("setUserData", data);
        Cookies.set("atoken", data.atoken, { expires: 365 });
        Cookies.set("rtoken", data.rtoken, { expires: 365 });
        Cookies.set("uid", data.id, { expires: 365 });
        this.cancelLogin();
        this.$store.commit("setIsLogin", true);
        this.$toast.clear();
        updateService();
      } catch (error) {
        console.log(error);
      }
    },
    toLogin() {
      if (!this.userName || !this.password) return;

      if (this.userName.length < 6) {
        this.$toast("Username must be at least 6 characters");
        return;
      }
      if (this.password.length < 6) {
        this.$toast("Password must be at least 6 characters");
        return;
      }

      if (this.isLoginType) {
        console.log("登录调用");
        this.$toast.loading({
          duration: 0,
        });
        loginByNameApi({
          name: this.userName,
          password: this.password,
        }).then((res) => {
          console.log(111, res);
          const { data, code, msg } = res;
          if (code == 0) {
            this.$store.commit("setUserData", data);
            Cookies.set("atoken", data.atoken, { expires: 365 });
            Cookies.set("rtoken", data.rtoken, { expires: 365 });
            Cookies.set("uid", data.id, { expires: 365 });
            this.cancelLogin();
            this.$store.commit("setIsLogin", true);
            this.$toast.clear();
            updateService();
          } else {
            this.$toast(msg);
          }
        });
      } else {
        let abuid = Cookies.get("abuid");
        console.log("注册调用", abuid);
        this.$toast.loading({
          duration: 0,
        });
        registerByNameApi({
          name: this.userName,
          password: this.password,
          uuid: abuid,
        }).then((res) => {
          const { msg, code } = res;
          if (code == 0) {
            console.log("111", res);
            const { uuid } = res.data;
            console.log("#uuid#",uuid)
            this.$toast.clear();
            this.$toast("Sign up successful, please sign in");
            this.changeLoginType();
            // Cookies.set("abuid", uuid, { expires: 365 });
            this.$store.commit("setAbuid", uuid);
          } else {
            this.$toast(msg);
          }
        });
      }
    },
    changeLoginType() {
      this.isLoginType = !this.isLoginType;
      this.userName = "";
      this.password = "";
    },
  },
  mounted() {
    
  },
};
</script>

<style lang="less">
@import url("./index.less");
</style>
