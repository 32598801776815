import Cookies from "js-cookie"
export default {
  state: {
    isLogin: Cookies.get('atoken') && Cookies.get('rtoken'),
    showLogin: false,
    userData: {},
    abuid: Cookies.get('abuid'),
  },
  getters: {

  },
  mutations: {
    setIsLogin(state, v) {
      state.isLogin = v
    },    
    setShowLogin(state, v) {
      state.showLogin = v
    },
    setUserData(state, v) {
      console.log("current user ",v);
      state.userData = v
      if (v.uuid){
        state.abuid = v.uuid
        Cookies.set('abuid',v.uuid,{expires: 3650}) // ten years
      }
    },
    setAbuid(state, v) {
       state.abuid = v
      Cookies.set('abuid',v,{expires: 3650}) // ten years
    }
  },
  actions: {
  },
}